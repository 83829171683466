import React from 'react';
import debounce from 'lodash/debounce';
import { createRoot } from 'react-dom/client';

import './index.scss';
import {App} from './App';
import Utils from './utilities/_index';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

const debounced = debounce((e) => {
  Utils.Message.pub('SCREEN_RESIZE', e);
}, 100);

window.addEventListener('resize', debounced);

root.render(<App />);

console.log("%c1Up 2Down time to CLOWN", "color: #f00;font-size: 8em; font-weight: bold; text-shadow: .03em .02em #000;");

serviceWorker.unregister();

/*
TODO:
make menu in header 'sticky' for mobile
*/