import {ApiUtil} from './general';

/*
* Generic service request
*
* @param { url } string -> Description: URL to be called
* @param { headers } json -> Description: headers changes
* @param { method } string -> Description: default to 'GET'
* @param { body } json -> Description: data to be sent to the api
* @return {promise}
* */
const Service = (url, headers = {}, method = 'GET', body) => {
  const meta = {
    method,
    headers: new Headers(headers),
    body
  };

  return fetch(url, meta)
    .then(ApiUtil);
};

export default Service;