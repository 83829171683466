import React, { useState, useEffect } from 'react';
import './Media.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Utils from '../../../utilities/_index';
import CONSTANTS from '../../../utilities/constants';

const Media = ({data}) => {
  const [displaying, setDisplying] = useState(0);
  const [active, setActive] = useState(0);
  const [startThumb, setStartThumb] = useState(0);
  const [thumbCount, setThumbCount] = useState(6);
  const [smallLayout, setSmallLayout] = useState(false);
  
  const setDisplay = () => {
    return data ?
      data.map((item, k) => {
        if (item.type === 'video') {
          return (
            <video
              muted 
              controls
              style={{
                margin: '0 auto',
                display: (displaying === k) ? 'block' : 'none',
                width: '100%'
              }}
              poster={item.thumb}
              key={k.toString()}
            >
              {
                item.uris.map((uri, k) => (
                  <source src={uri} type="video/mp4" key={uri + k} />
                ))
              }
              
              Your browser does not support the video tag.
            </video>
          );
        } else {
          return <img 
            src={item.uri}
            alt={item.title}
            title={item.title}
            style={{
              display: (displaying === k) ? 'block' : 'none'
            }}
            key={k.toString()}
          />;
        }
      }) : null;
  };

  useEffect(() => {
    const screenChange = val => {
      if (val >= (CONSTANTS.SIZE * 100)) {
        setThumbCount(6);
      } else if (val < (CONSTANTS.SIZE * 100) && val > (CONSTANTS.SIZE * 55)) {
        setThumbCount(4);
      } else {
        setThumbCount(2);
      }

      setStartThumb(0);
      setSmallLayout(val < (CONSTANTS.SIZE * 55));
    };

    Utils.Message.sub('SCREEN_RESIZE', e => Utils.ScreenWidth(e, screenChange), null, true);
  }, []);

  const handleClickBack = () => {
    if ((startThumb - thumbCount) <= 0) {
      setStartThumb(0);
    } else {
      setStartThumb(startThumb - thumbCount);
    }
  };

  const handleClickNext = () => {
    if (startThumb + thumbCount < data.length) {
      setStartThumb(startThumb + thumbCount);
    }
  };
  
  const handleClickMedia = (index) => {
    setActive(index);
    setDisplying(index);
  };

  const displayThumbs = items => {
    return items.map((item, i) => (
      <div
        className={`col-${24 / thumbCount} thumb ${i === active ? 'active' : ''} center`}
        onClick={() => handleClickMedia(i)}
        style={{
          display: (i >= startThumb && i <= (startThumb + (thumbCount - 1))) ? 'block' : 'none'
        }}
        key={item.id}
      >
        <img src={item.thumb} alt={item.title} title={item.title} />
        <div className="carrot"></div>
        <div className="iconAngle"></div>
        <div className="icon">
          <FontAwesomeIcon icon={item.type === 'image' ? 'image' : 'video'} />
        </div>
      </div>
    ));
  };

  return (
    <div className="container mediaContainer">
      <div className="row">
        {
          !smallLayout &&
          <div className="col-1"></div>
        }
        <div className={`col-${smallLayout ? '24 alpha omega' : '22'}`}>
          <div className="mediaPlayerContainer">
            {
              setDisplay()
            }
          </div>
          <div className="container thumbNailContainer">
            <div className="row">
              {
                displayThumbs(data)
              }
            </div>
            {
              (data.length > thumbCount) &&
              <div className="row">
                <div className="col-12">
                  <button disabled={startThumb <= 0} onClick={handleClickBack}>
                    <FontAwesomeIcon icon="angle-double-left" />
                  </button>
                </div>
                <div className="col-12">
                  <button disabled={startThumb + thumbCount >= data.length} onClick={handleClickNext}>
                    <FontAwesomeIcon icon="angle-double-right" />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
        {
          !smallLayout &&
          <div className="col-1"></div>
        }
      </div>
    </div>
  );
};

export default Media;