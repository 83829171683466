import React, {useState, useEffect} from 'react';
import Title from "./header/title/Title";
import Space from "./body/space/Space";
import Spacer from "./shared/spacer/Spacer";
import axios from "axios";
import CONSTANTS from "../utilities/constants";
import {NavLink} from "react-router-dom";

export const VerifyEmail = ({match}) => {
  const [content, setContent] = useState(['Checking']);

  const updateContent = value => {
    value.push(<>Back to <NavLink to="/">Sploodies.com</NavLink></>);
    setContent(value);
  };

  useEffect(() => {
    if (match.params.code) {
      axios.post(
        CONSTANTS.SERVICE_URLS.EMAIL,
        {email: null, verifyCode: match.params.code},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': "X-Requested-With, accept, content-type"
          }
        })
        .then(response => {
          const value = CONSTANTS.EMAIL_STATUS.has(response.data.status)
          ? 'Your email has been verified, thank you.'
          : `Sorry we couldn't verify your email.`;

          updateContent([value]);
        })
        .catch(console.log);
    } else {
      updateContent(['Can\'t verify your email.']);
    }
  }, [match.params.code]);

  return (
    <div className="emailContainer">
      <Title hasVideo={false} />
      <Spacer />
      <Space data={
        {
          id: 'emailVerify',
          title: 'Verify Your Email',
          content
        }
      } side={'RIGHT'} />
    </div>
  );
};