const ENVIRONMENT = process.env.NODE_ENV;
const SERVICE_URLS = enviro();
const SIZE = 12;
const ERROR_MESSAGES = {
  NOT_FOUND: {
    PAGE: 'Page not found.',
    CONTENT: 'User not found.',
    SITE: 'Site not found.'
  }
};
const EMAIL_REGEX = /^(.)+@(.+)\.(.)+$/;
const EMAIL_STATUS = new Set([
  'ENROLLED',
  'SAVED',
  'VERIFIED',
  'RESENT'
]);
const USER_AGENTS = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i;

export default {
  EMAIL_STATUS,
  EMAIL_REGEX,
  ENVIRONMENT,
  ERROR_MESSAGES,
  SERVICE_URLS,
  SIZE,
  USER_AGENTS
};

function enviro () {
  let data;

  if (ENVIRONMENT === 'development') {
    data = {
      DATA: './data/content.json',
      EMAIL: 'https://www.sploodies.com/dev/v2/data/email.php'
    };
  } else {
    data = {
      DATA: './data/content.json',
      EMAIL: '/data/email.php'
    };
  }

  return data;
}