import React from 'react';
import './App.scss';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';

import {Home} from './componenets/Home';
import {VerifyEmail} from './componenets/VerifyEmail';


export const App = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Outlet />}>
          <Route exact path="/" element={<Home />}>
            <Route exact path="email" element={<VerifyEmail />}>
              <Route exact path=":code" element={<VerifyEmail />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/"/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

