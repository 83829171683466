const ScreenWidth = (event, fn) => {
  let screenWidth;

  if (event) {
    const target = event.target;
    screenWidth = target.outerWidth || target.screen.availWidth || target.screen.width || (target.innerWidth - 25);
  } else {
    screenWidth = window.outerWidth || window.screen.availWidth || window.screen.width || (window.innerWidth - 25);
  }

  if (fn) {
    fn(screenWidth);
  } else {
    return screenWidth;
  }
};

export default ScreenWidth;