import React from 'react';
import './Title.scss';

const Title = ({ hasVideo }) => {
  return (
    <div className={`col-24 alpha omega alignCenter titleContainer${hasVideo ? '' : ' noVid'}`}>
      <h1>SPLOODIES</h1>
      <h2>Yeah, it's a game!</h2>
    </div>
  );
};

export default Title;