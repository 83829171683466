import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faSteamSquare,
  faSteam
} from '@fortawesome/free-brands-svg-icons'
import {
  faPause,
  faPlay,
  faEnvelopeOpenText,
  faImage,
  faVideo,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCircleNotch
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPause,
  faPlay,
  faImage,
  faVideo,
  faEnvelopeOpenText,
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faSteamSquare,
  faSteam,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCircleNotch
);