const SortBy = (data, toSortBy, ascending = true) => {  
  toSortBy = Array.isArray(toSortBy) ? toSortBy : [toSortBy];

  const max = toSortBy.length;
  let sortCounter = 0;

  const checkForSorting = (x, z, key) => {
    if (max > sortCounter) {
      return sorting(x, z, key);
    }
  };

  const sorting = (x, z, key) => {
    key = key || toSortBy[0];

    if (x[key] > z[key]) return ascending ? 1 : -1;

    if (x[key] < z[key]) return ascending ? -1 : 1;

    if (x[key] === z[key]) {
      const checked = checkForSorting(x, z, toSortBy[1]);
      
      return (checked === undefined) ? 0 : checked;
    }
  };

  return data.sort(sorting);
};

export default SortBy;