import React, { useRef, useEffect, useState, useContext } from 'react';
import './LargeVideo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AgentType from '../../../utilities/context';
import Utils from '../../../utilities/_index';
import CONSTANTS from '../../../utilities/constants';
import Title from '../title/Title';
import Menu from '../menu/Menu';
import Spacer from '../../shared/spacer/Spacer';

const LargeVideo = ({ videos, menu, playVid }) => {
  const agentType = useContext(AgentType);
  const vidRef = useRef(null);
  const [vid, setVid] = useState(null);
  const [screenSize, setScreenSize] = useState('FULL');
  const [trueWidth, setTrueWidth] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [hasVideos, setHasVideos] = useState(false);

  useEffect(() => {
    setHasVideos(!!(videos && videos.length));
  }, [videos]);

  useEffect(() => {
    if (playVid) {
      setPlaying(true);
    }
  }, [playVid]);

  useEffect(() => {
    setVid(vidRef.current);
  }, [vidRef]);

  useEffect(() => {
    if (vid) {
      vid[playing ? 'play' : 'pause']();
    }
  }, [playing, vid]);

  const handleClickPlay = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    const screenChange = val => {
      setTrueWidth(val);

      if (val >= (CONSTANTS.SIZE * 100)) {
        setScreenSize('FULL');
      } else if (val < (CONSTANTS.SIZE * 100) && val > (CONSTANTS.SIZE * 55)) {
        setScreenSize('MED');
      } else {
        setScreenSize('SMALL');
      }
    };

    Utils.Message.sub('SCREEN_RESIZE', e => Utils.ScreenWidth(e, screenChange), null, true);
  }, []);

  return (
    <section className="container largeVideoContainer">
      <div className="row max">
        <div className="col-24 alpha omega">
          {
            hasVideos && 
            (screenSize !== 'SMALL') &&
            <video 
              autoPlay 
              loop 
              muted 
              ref={vidRef} 
              style={{ margin: '0 auto', display: 'block', width: '100%' }}
            >
              {
                videos.map((vid, k) => (
                  <source src={`./videos/${vid.name}.${vid.type}`} type={`video/${vid.type}`} key={vid.name + k} />
                ))
              }
            </video>
          }
          <Title hasVideo={hasVideos} />
          {
            hasVideos && 
            (agentType === 'DESKTOP') &&
            (screenSize !== 'SMALL') &&
            <button className="pauseButton" onClick={handleClickPlay}>
              <FontAwesomeIcon icon={playing ? 'pause' : 'play'} />
            </button>
          }
          {
            menu &&
            <Menu data={menu} screenWidth={trueWidth} hasVideo={hasVideos} />
          }
          {
            !videos &&
            <Spacer />
          }
        </div>
      </div>
    </section>
  );
};

export default LargeVideo;