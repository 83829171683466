import React, { useEffect, useState } from 'react';
import './Space.scss';
import Utils from '../../../utilities/_index';
import CONSTANTS from '../../../utilities/constants';

const Space = ({ data, side }) => {
  const [screenSize, setScreenSize] = useState('FULL');

  useEffect(() => {
    const screenChange = val => {
      if (val >= (CONSTANTS.SIZE * 100)) {
        setScreenSize('FULL');
      } else if (val < (CONSTANTS.SIZE * 100) && val > (CONSTANTS.SIZE * 55)) {
        setScreenSize('MED');
      } else {
        setScreenSize('SMALL');
      }
    };

    Utils.Message.sub('SCREEN_RESIZE', e => Utils.ScreenWidth(e, screenChange), null, true);
  }, []);

  const setStyle = () => {
    const colNum = screenSize === 'SMALL' ? '24' : '16';
    const alphaOmega = (screenSize === 'FULL')
      ? (side === 'RIGHT') ? ' alpha' : ' omega'
      : '';

    return `col-${colNum}${alphaOmega}`;
  };

  return (
    <div className={`container spaceContainer ${data.id} ${side}`}>
      <section className="row">
        {
          (screenSize !== 'SMALL') &&
          (side === 'LEFT') &&
          <div className="col-8"></div>
        }
        <div className={setStyle()}>
          <h2>
            {data.title}
          </h2>
          <div className="mobileIcon"></div>
          {
            data.content.map((text, k) => (
              <p key={k.toString()}>{text}</p>
            ))
          }
        </div>
        {
          (screenSize !== 'SMALL') &&
          (side === 'RIGHT') &&
          <div className="col-8"></div>
        }
      </section>
    </div>
  );
};

export default Space;