import CONSTANTS from './constants';

const MarkDown = function (data) {
  // kill regular HTML, do this in the BE
  data = data.replace(/<.+?>|<\/.+?>/gim, '');

  // needs memoize
  // replace markdown, add to utils
  data = data.replace(/\[b\](.+?)\[\/b\]/gim, '<strong>$1</strong>');
  data = data.replace(/\[i\](.+?)\[\/i\]/gim, '<em>$1</em>');
  data = data.replace(/\[link="(.+?)"\](.+?)\[\/link\]/gim, '<a href=$1>$2</a>');

  // data = data.replace(/\[img="(.+?(\.[a-zA-Z]{3,4}))"( txt="(.+?)")?\]/gim, '<div class="imgContainer left"><img src=$1 alt=$4 /></div>');
  // data = data.replace(/\[vid="(.+?\.([a-zA-Z4]{3}))"\]/gim, '<video width="320" height="240" controls><source src=$1 type="video/$2">You can\'t do video.</video>');


  let temp = data.split(/\f|\r|\n/);
  let itemCount = 0;

  data = '';

  for (var i = 0, ii = temp.length; i < ii; i++) {
    var txt = temp[i];

    if (txt.indexOf('* ') === 0) {
      // set text
      txt = `<li>${txt.substring(2)}</li>`;

      // retroactively add in UL
      if (itemCount === 0) {
        itemCount += 1;
        txt = '<ul class="list">' + txt;
      }
    }

    if (itemCount >= 1 && (!temp[i + 1] || (temp[i + 1] && temp[i + 1].indexOf('* ') !== 0))) {
      txt += '</ul>';
      itemCount = 0;
    }

    data += txt;
  }

  return data;
};

const CaptureClick = function (e) {
  const target = e.target;

  if (target.tagName === 'A') {
    const hrf = target.getAttribute('href');

    if (hrf.indexOf('#') === 0) {
      e.preventDefault();

      const scrollTarget = document.getElementById(hrf.slice(1));

      if (scrollTarget) {
        scrollTarget.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    } else {
      target.setAttribute('target', '_blank');
    }
  }
};

/*
* @description Convert response to JSON or throw an error
* @param { response } object -> Description: fetch api response object
* @return { JSON || ERROR }
* */
function ApiUtil(response) {
  let data;
  let errorCode;

  if (!response) {
    throw new Error('No response');
  }

  if (response.ok) {
    data = response.json();

    return data.then(d => {
      d.utils = new Utils(d);
      return d;
    });
  }

  switch (response.status) {
    case 404:
      errorCode = 'NOT_FOUND.PAGE';
      break;
    case 409:
      errorCode = 'NOT_FOUND.CONTENT';
      break;
    case 500:
      errorCode = 'NOT_FOUND.SITE';
      break;
    default:
      errorCode = 0;
  }

  throw new Error(ErrorMessage(errorCode));
}

/*
* @description Error code to human readable message
* @param { errorCode } string
* @return { string }
* */
function ErrorMessage(errorCode) {
  // TODO set up human readable messages
  const err = errorCode.split('.');

  function bits(obj) {
    if (typeof obj === 'string' || typeof obj === 'number') {
      return obj;
    }

    return bits(obj[err.shift()]);
  }

  return bits(CONSTANTS.ERROR_MESSAGES);
}

/*
* @description General utility function setup
* @param { content } any -> Description: takes in any data to be used for any of the provided functions
* @return { this }
* */
function Utils(content) {
  this.isArray = (data = content) => {
    return (data && Array.isArray(data));
  };

  return this;
}

export {
  ApiUtil,
  Utils,
  MarkDown,
  CaptureClick
};