import React from 'react';
import './Body.scss';
import Spacer from '../shared/spacer/Spacer';
import Space from './space/Space';
import Media from './media/Media';

export const Body = ({ game, media}) => {
  return (
    <div className="bodyContainer">
      {
        game && 
        game.data && 
        [
          <span id={game.meta.id} key="gameId" />,
          game.data.map((item, index) => (
            <Space data={item} side={(index % 2 === 0) ? 'RIGHT' : 'LEFT'} key={item.id} />
          )),
          <Spacer key="gameSpacer" />
        ]
      }
      {
        media &&
        media.data && 
        !!(media.data.length) &&
        [
          <span id={media.meta.id} key="mediaId" />,
          <Media data={media.data} key="media" />,
          <Spacer key="mediaSpacer" />
        ]
      }
    </div>
  );
};
