import React from 'react';
import './Header.scss';
import LargeVideo from './large-video/LargeVideo';
import Spacer from '../shared/spacer/Spacer';

export const Header = ({ menu, videos, playVid }) => {
  return (
    <header className="headerContainer">
      {
        videos &&
        <>
          <LargeVideo videos={videos} playVid={playVid} menu={menu} />
          <Spacer />
        </>
      }
    </header>
  );
};
