import { _CACHE_ } from './cache';

class Messages {
  constructor() {
    this.cache = _CACHE_.messages;
    this.cache.FN = {};
  }

  sub = (name, fn, fallback = null) => {
    if (!name) return;

    const arg = (this.cache[name] === undefined) ? fallback : this.cache[name];

    if (typeof fn === 'function') {
      if (!Array.isArray(this.cache.FN[name])) {
        this.cache.FN[name] = [];
      }

      if (this.cache.FN[name].indexOf(fn) === -1) {
        this.cache.FN[name].push(fn);
      }

      return fn(arg);
    }

    return arg;
  };

  unSub = (name, fn) => {
    if (name && fn) {
      const index = this.cache.FN[name].indexOf(fn);

      if (index >= 0) {
        this.cache.FN[name] = this.cache.FN[name].filter(i => i !== fn);
      }
    }
  };

  pub = (name = 'NULL', value = '') => {
    if(this.cache[name] !== value) {
      this.cache[name] = value;

      if (Array.isArray(this.cache.FN[name]) && this.cache.FN[name].length) {
        this.cache.FN[name].forEach(fn => fn(value));
      }
    }
  };

  clear = (name, check = false) => {
    if (name) {
      delete this.cache[name];
      delete this.cache.FN[name];
    }

    if (check === true) {
      this.cache = _CACHE_.messages = {};
    }
  };
}

const Message = new Messages();

export default Message;