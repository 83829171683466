import React, { useState, useEffect } from 'react';
import './Footer.scss';
// import MailingList from './MailingList';
import Utils from '../../utilities/_index';
import CONSTANTS from '../../utilities/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer = ({ socialMedia }) => {
  const [screenWidth, setScreenWidth] = useState('FULL');

  const setCompanyStyle = () => {
    const colNum = screenWidth === 'SMALL' ? '24' : '12';
    const alphaOmega = screenWidth === 'FULL' ? ' alpha' : '';
    const align = screenWidth === 'SMALL' ? ' alignCenter' : '';

    return `col-${colNum}${alphaOmega}${align}`;
  };

  const setLinksStyle = () => {
    const colNum = screenWidth === 'SMALL' ? '24' : '12';
    const alphaOmega = screenWidth === 'FULL' ? ' omega' : '';
    const align = screenWidth === 'SMALL' ? ' alignCenter' : ' alignRight';

    return `col-${colNum}${alphaOmega}${align}`;
  };

  useEffect(() => {
    const screenChange = val => {
      if (val >= (CONSTANTS.SIZE * 100)) {
        setScreenWidth('FULL');
      } else if (val < (CONSTANTS.SIZE * 100) && val > (CONSTANTS.SIZE * 55)) {
        setScreenWidth('MID');
      } else {
        setScreenWidth('SMALL');
      }
    };

    Utils.Message.sub('SCREEN_RESIZE', e => Utils.ScreenWidth(e, screenChange), null, true);
  }, []);

  return (
    <div className="container footerContainer">
      <span id="stuff" />
        {/*<div className="row">*/}
        {/*  <div className="col-24">*/}
        {/*    <MailingList />*/}
        {/*  </div>*/}
        {/*</div>*/}
      {
        socialMedia &&
        (socialMedia.length > 0) &&
        [
          <div className="row" key="socialMedia">
            <div className="col-6"></div>
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {
                    socialMedia.map((item, k, ary) => (
                      <div className={`oneOf-${ary.length} alignCenter socialIcon`} key={item}>
                        <FontAwesomeIcon icon={['fab', item + '-square']} />
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="col-6"></div>
            </div>
          </div>,
          <div className="row max hr" key="hr" />
        ]
      }
      <div className="row company">
        <div className={setCompanyStyle()}>1 Up 2 Down Studios LLP</div>
        <div className={setLinksStyle()}>
          {/* About 1U 2D | Contact Us | Privacy | Terms */}
        </div>
      </div>
    </div>
  );
};
