import React, { useEffect, useState } from 'react';
import '../utilities/fontAwesome';
import Utils from "../utilities/_index";
import CONSTANTS from "../utilities/constants";
import {Header} from "./header/Header";
import {Body} from "./body/Body";
import {Footer} from "./footer/Footer";
// import Overlay from './componenets/overlay/Overlay';

export const Home = () => {
  const [menu, setMenu] = useState(null);
  const [videos, setVideos] = useState(null);
  const [playVid, setPlayVid] = useState(false);
  const [game, setGame] = useState(null);
  const [media, setMedia] = useState(null);
  const [socialMedia, setSocialMedia] = useState(null);

  useEffect(() => {
    Utils.Service(CONSTANTS.SERVICE_URLS.DATA)
      .then(response => {
        if (response.menu) setMenu(response.menu);
        if (response.videos) setVideos(response.videos);
        if (response.game) setGame(response.game);
        if (response.socialMedia) setSocialMedia(response.socialMedia);
        if (response.media) {
          const sortBy = response.media.sortBy;
          let data = response.media.items;

          switch (sortBy) {
            case 'date':
              data = Utils.SortBy(data, ['date', 'title'], false);

              break;
            default:
          }

          setMedia({
            meta: response.media.meta,
            data
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Header menu={menu} videos={videos} playVid={playVid} />
      <Body game={game} media={media} />
      <Footer socialMedia={socialMedia} />
      {/*<Overlay />*/}
    </>
  )
};
