import React from 'react';
import './Menu.scss';
import CONSTANTS from '../../../utilities/constants';

const Menu = ({ data, screenWidth, hasVideo }) => {
  const setClassNames = () => {
    let classNames = 'alignCenter';

    if ((CONSTANTS.SIZE * 38) < screenWidth) {
      classNames = `oneOf-${data.length} alpha omega ${classNames}`;
    }

    return classNames;
  };

  return (
    <nav className={`container menuContainer${hasVideo ? '' : ' noVid'}`}>
      <ul className="row max">
        {
          data.map(item => (
            <li className={setClassNames()} key={item.id}>
              <a href={'#' + item.id}>{item.text}</a>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export default Menu;